.myfooter {
  /* background-color: rgb(0, 0, 0); */
  /* Blur effect */
  background-color: rgba(0, 0, 0, 0);
  /* backdrop-filter: blur(10px); */
  border: none; /* Remove border */
  box-shadow: none; /* Remove default shadow */
  padding: 0.5rem 1rem; /* Padding for better spacing */
  /* Ensure header stays on top */
  /* z-index: 1000; */
  /* Fixed position */
  position: fixed;
  /* Stick to the bottom */
  bottom: 0;
  justify-content: space-between; /* Space between copyright and social icons */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center items vertically */
  width: 100%; /* Full width */

  /* Font size smaller */
  font-size: 0.8rem;
}
