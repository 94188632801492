
/* Define Variables */
:root {
  --card-transparent-background: rgba(0, 0, 0, 0.24);
  --card-blur: blur(5px);
  --card-border-radius: 17px;
  --card-padding: 1rem;
  --card-max-width: 666px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

