.home {

}


/* Blinking star */
.star {
  position: fixed;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: blink var(--blink-duration, 2s) infinite ease-in-out;
  opacity: 0.8;
  animation-duration: var(--blink-duration); /* Use randomized duration */
}

/* Blinking effect with dynamic scale */
@keyframes blink {
  0% {
    opacity: 0.2;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.5);
  }
}


/* Mission Card Styles */
.mission-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  /* position: relative; */
}

.mission-card {
  /* width: 80%; */
  max-width: 800px;
  background-color: transparent;
  /* Background image for the card */
  /* background-image: url('../assets/test3.png'); */
  /* contain full img */
  /* background-size: cover; */
  /* background-position: center; */
  /* border-radius: 50px; */
  padding: 1rem;
  box-shadow: none;
}

.mission-card-content {
  background-color: var(--card-transparent-background);
  backdrop-filter: var(--card-blur);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  max-width: var(--card-max-width);

  /* Increase spacing between lines */
  line-height: 1.5;
}

.mission-card-content .p-button {
  margin-top: 1rem;
}