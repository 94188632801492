.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 80vh;
  justify-content: center;
}

.contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  background-color: var(--card-transparent-background);
  backdrop-filter: var(--card-blur);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  max-width: var(--card-max-width);

  /* Increase spacing between lines */
  line-height: 1.5;
}

/* Social buttons */
.social-buttons {
  display: flex;
  gap: 2rem;
}

.social-buttons .p-button {
  color: white;
}

/* Special on hover effect */
.social-buttons .p-button:hover {
  /* color: var(--primary-color); */
  border: 2px solid white;
}

.profile-description {
  /* max-width: 600px; */
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  /* Black and white */
  filter: grayscale(100%);
}

.contact-form-container {
  width: 100%;
  /* max-width: 600px; */
}

.contact-form-card {
  background-color: var(--card-transparent-background);
  backdrop-filter: var(--card-blur);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  max-width: var(--card-max-width);
}

/* Contact form field styles */
.contact-form-card .p-field{
  /* flex */
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6rem;
}

.contact-form-card .p-field :hover {
  border: 1px solid white;
}

.contact-form-card .p-field input {
  width: 100%;
  max-width: 400px;
}

/* same for input text area */
.contact-form-card .p-field textarea {
  width: 100%;
  max-width: 400px;
}


/* Captcha container */
