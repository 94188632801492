/* Main header styles */
.p-menubar {
  background: rgba(128, 128, 128, 0.137); /* Transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  border: none; /* Remove border */
  box-shadow: none; /* Remove default shadow */
  padding: 0.5rem 1rem; /* Padding for better spacing */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure header stays on top */
}

/* Customize the logo */
.p-menubar-start img {
  height: 40px; /* Adjust the logo height */
  margin-left: 10px; /* Add margin to the left */
}

.p-menubar-root-list {
  margin-left: auto; /* set items to the right */
}

/* When the screen is smaller, display menu to the right */
.p-menubar-button {
    margin-left: auto;
}