.trusted-partners-carousel {
    padding: 2rem 0;
}

.company-item {
    padding: 0rem;
    box-shadow: none;
    border-radius: 10px;

    background-color: transparent;
    /* background-color: rgba(255, 255, 255, 0.33); */

    /* center the content */
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
}

.company-logo {
    /* width: 100px; */
    height: 70px;
    object-fit: cover;
    margin-bottom: 1rem;
    /* center the image */
}