.stories-list-container {
  /* justify-content: center; */
  max-width: var(--card-max-width);
  margin: auto;
}

.stories-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.story-card {
  background-color: var(--card-transparent-background);
  backdrop-filter: var(--card-blur);
  /* background-color: red; */
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  max-width: 250px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.2s;
}

.story-card:hover {
  transform: scale(1.02);
}

.story-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: var(--card-border-radius);
}

.story-card div {
  padding: 0.5rem 0;
  font-size: 1rem;
}

.story-content-card {
  background-color: var(--card-transparent-background);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  max-width: var(--card-max-width);
  /* blur */
  backdrop-filter: var(--card-blur);
  margin: auto;

  /* Autmoatically crop content */
  overflow: hidden;
}

/* Max image size of stories */
.story-content-card img {
  width: 100%;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.back-button button {
  color: white;
}

.back-button button:hover {
  color: var(--primary-color);
}
